<template>
    <b-form class="mt-3" @submit.prevent="submit">
        <b-form-group>
            <two-dates-picker :date-debut="etape.dateDebut"
                              :date-fin="etape.dateFin"
                              :min-date="evenement.dateDebut.toISOString(true)"
                              :max-date="evenement.dateFin.toISOString(true)"
                              @dateDebutChange="updateDateDebut"
                              @dateFinChange="updateDateFin"/>
        </b-form-group>

        <b-form-group label="Titre">
            <b-form-input type="text" v-model="form.nom"/>
        </b-form-group>

        <b-form-group>
            <label v-b-tooltip.hover="'Sélectionnez une partie du texte pour afficher les options de mise en forme'"
                   slot="label">
                Description &middot;
                <font-awesome-icon icon="question-circle"/>
            </label>
            <balloon-editor v-model="form.description"/>
        </b-form-group>

        <div class="text-center">
            <b-button type="submit" variant="success">
                <font-awesome-icon icon="check"/>
                Valider
            </b-button>
        </div>
    </b-form>
</template>

<script>
    const BalloonEditor  = () => import('@/components/BalloonEditor');
    const TwoDatesPicker = () => import('@/components/TwoDatesPicker');

    export default {
        name: "EditEtapeEvenement",
        components: {BalloonEditor, TwoDatesPicker},
        props: {
            evenement: {
                type: Object,
                required: true
            },
            callback: {
                type: Function,
                required: true
            },
            etape: {
                type: Object,
                default: () => ({
                    nom: null,
                    dateDebut: null,
                    dateFin: null,
                    description: null
                })
            }
        },
        data: () => ({
            form: {}
        }),
        methods: {
            updateDateDebut(val) {
                this.form.dateDebut = val;
            },
            updateDateFin(val) {
                this.form.dateFin = val;
            },
            submit() {
                this.callback(this.form);
            }
        },
        mounted() {
            this.form = {...this.etape};
        }
    }
</script>
